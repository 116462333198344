import { createContext, ReactNode, useMemo, useReducer } from 'react';
import { EPGReducerActionType } from './action';
import epgReducer from './reducer';

export type EPGContextType = {
  activeDayIndex: number;
  isFetchingEPGGrid: boolean;
  isFetchingTimeslices: boolean;
  selectedDayUrl: string;
  updateActiveDayIndex: (activeDayIndex: number) => void;
  updateIsFetchingEPGGrid: (isFetchingEPGGrid: boolean) => void;
  updateIsFetchingTimeslices: (isFetchingTimeslices: boolean) => void;
  updateSelectedDayUrl: (selectedDayUrl: string) => void;
};

const EPGContext = createContext<EPGContextType | undefined>(undefined) as React.Context<EPGContextType>;

type EPGProviderProps = {
  children: ReactNode;
  initialDayUrl: string;
};

function EPGProvider({ children, initialDayUrl }: EPGProviderProps): JSX.Element {
  const [{ activeDayIndex, isFetchingEPGGrid, isFetchingTimeslices, selectedDayUrl }, epgDispatcher] = useReducer(
    epgReducer,
    {
      activeDayIndex: 0,
      isFetchingEPGGrid: false,
      isFetchingTimeslices: false,
      selectedDayUrl: initialDayUrl,
    }
  );

  const updateActiveDayIndex = (value: number) =>
    epgDispatcher({
      type: EPGReducerActionType.UpdateActiveDayIndex,
      payload: {
        activeDayIndex: value,
      },
    });

  const updateIsFetchingEPGGrid = (value: boolean) =>
    epgDispatcher({
      type: EPGReducerActionType.UpdateIsFetchingEPGGrid,
      payload: {
        isFetchingEPGGrid: value,
      },
    });

  const updateIsFetchingTimeslices = (value: boolean) =>
    epgDispatcher({
      type: EPGReducerActionType.UpdateIsFetchingTimeslices,
      payload: {
        isFetchingTimeslices: value,
      },
    });

  const updateSelectedDayUrl = (value: string) =>
    epgDispatcher({
      type: EPGReducerActionType.UpdateSelectedDayUrl,
      payload: {
        selectedDayUrl: value,
      },
    });

  const value: EPGContextType = useMemo(
    () => ({
      activeDayIndex,
      isFetchingEPGGrid,
      isFetchingTimeslices,
      selectedDayUrl,
      updateActiveDayIndex,
      updateIsFetchingEPGGrid,
      updateIsFetchingTimeslices,
      updateSelectedDayUrl,
    }),
    [activeDayIndex, isFetchingEPGGrid, isFetchingTimeslices, selectedDayUrl]
  );

  return <EPGContext.Provider value={value}>{children}</EPGContext.Provider>;
}

export { EPGContext, EPGProvider };
