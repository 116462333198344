import { ProgramGuide } from '@dce-front/hodor-types/modules/page_container_content/electronic_program_guide/definitions';
import { memo } from 'react';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchRequestTypes } from '../../../services/types';
import { EPGProvider } from '../store/context';
import ElectronicProgramGuide from './ElectronicProgramGuide';

export type ElectronicProgramGuideContainerProps = {
  url: string;
} & FromProp;

function ElectronicProgramGuideContainer({ from, url }: ElectronicProgramGuideContainerProps): JSX.Element | null {
  const [{ isLoading, isFetching, error, data }] = useQueryTemplate<ProgramGuide>(url, {
    from,
    template: FetchRequestTypes.EPG,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  return data ? (
    <EPGProvider initialDayUrl={data.rubriques[1].URLPage}>
      <ElectronicProgramGuide rubrics={data.rubriques} timeSlices={data.timeSlices} isFetchingDays={isFetching} />
    </EPGProvider>
  ) : null;
}

export default memo(ElectronicProgramGuideContainer);
