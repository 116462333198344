import { HorizontalList } from '@canalplus/mycanal-sharedcomponent';
import classnames from 'classnames';
import TemplateHeaderCanal from '../../../../components/TemplateHeader/TemplateHeader';
import NavigationButton from '../NavigationButton/NavigationButton';
import styles from './ContentRowGuide.css';

export type ContentRowGuideProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  isHorizontalList?: boolean;
  role?: React.AriaRole;
  title?: string;
  type?: string;
};

/**
 * Displays contents in a scrollable row.
 * If `title` , it renders also a header.
 *
 * @param title             The title of the contentRow
 * @param isHorizontalList  Put the children in a HorizontalList
 * @param children          Elements to render
 */
function ContentRowGuide({
  className = '',
  title,
  type = '',
  children,
  isHorizontalList = false,
  role,
}: ContentRowGuideProps): JSX.Element {
  return (
    <div
      data-testid={`contentRowGuide__${type}`}
      className={classnames(styles.contentRowGuide, { [className]: !!className })}
      {...(role && { role })}
    >
      {title && (
        <div className={styles.contentRowGuide__header}>
          <TemplateHeaderCanal title={title} />
        </div>
      )}
      {isHorizontalList && Array.isArray(children) && children.length && (
        <HorizontalList
          buttonPrevious={<NavigationButton type="previous" shouldHideOnMobile />}
          buttonNext={<NavigationButton type="next" shouldHideOnMobile />}
        >
          {children}
        </HorizontalList>
      )}
      {!isHorizontalList && (
        <div
          className={styles.contentRowGuide__rowWrapper}
          data-e2e="contentRowGuideWrapper"
          // don't add role="row" if container does not have role attribute
          {...(role && { role: 'row' })}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default ContentRowGuide;
