import classNames from 'classnames';
import IconChevronLeft from '../../../../assets/svg/chevronLeft.svg';
import IconChevronRight from '../../../../assets/svg/chevronRight.svg';
import I18n, { I18nMyCanalFunction } from '../../../../lang';
import styles from './NavigationButton.css';

export type NavigationButtonProps = {
  shouldHideOnMobile?: boolean;
  onClick: () => void;
  type: 'previous' | 'next';
  t: I18nMyCanalFunction;
};

function NavigationButton({ shouldHideOnMobile = false, onClick, t, type }: NavigationButtonProps): JSX.Element {
  const title = type === 'previous' ? t('HorizontalPaging.previous') : t('HorizontalPaging.next');
  return (
    <button
      aria-label={title}
      className={classNames(styles.NavigationButton, styles[`NavigationButton--${type}`], {
        [styles['NavigationButton--shouldHideOnMobile']]: shouldHideOnMobile,
      })}
      onClick={onClick}
      title={title}
      type="button"
    >
      {type === 'previous' ? <IconChevronLeft /> : <IconChevronRight />}
    </button>
  );
}

export { NavigationButton as SimpleNavigationButton };

export default I18n.consumer(NavigationButton);
