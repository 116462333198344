export enum EPGReducerActionType {
  UpdateActiveDayIndex = 'updateActiveDayIndex',
  UpdateIsFetchingEPGGrid = 'updateIsFetchingEPGGrid',
  UpdateIsFetchingTimeslices = 'updateIsFetchingTimeslices',
  UpdateSelectedDayUrl = 'updateSelectedDayUrl',
}

type ActionUpdateActiveDayIndex = {
  type: EPGReducerActionType.UpdateActiveDayIndex;
  payload: {
    activeDayIndex: number;
  };
};

type ActionUpdateIsFetchingEPGGrid = {
  type: EPGReducerActionType.UpdateIsFetchingEPGGrid;
  payload: {
    isFetchingEPGGrid: boolean;
  };
};

type ActionUpdateIsFetchingTimeslices = {
  type: EPGReducerActionType.UpdateIsFetchingTimeslices;
  payload: {
    isFetchingTimeslices: boolean;
  };
};

type ActionUpdateSelectedDayUrl = {
  type: EPGReducerActionType.UpdateSelectedDayUrl;
  payload: {
    selectedDayUrl: string;
  };
};

export type EPGReducerAction =
  | ActionUpdateActiveDayIndex
  | ActionUpdateIsFetchingEPGGrid
  | ActionUpdateIsFetchingTimeslices
  | ActionUpdateSelectedDayUrl;
