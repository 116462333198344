import { DEFAULT_RATIO } from '@canalplus/mycanal-commons';
import { ProgramGuideRubric } from '@dce-front/hodor-types/modules/page_container_content/electronic_program_guide/definitions';
import classNames from 'classnames';
import { useCallback, useContext } from 'react';
import { EPGContext } from '../../store/context';
import ContentRowGuide from '../ContentRowGuide/ContentRowGuide';
import styles from './DaySelector.css';

export type DaySelectorProps = {
  rubrics: ProgramGuideRubric[];
};

/**
 * DaySelector
 *
 * Displays some day items horizontally
 * with possibiliy to scroll inside
 *
 * @param rubrics                  Array of days to be displayed
 */

function DaySelector({ rubrics }: DaySelectorProps): JSX.Element {
  const { activeDayIndex, isFetchingEPGGrid, isFetchingTimeslices, updateActiveDayIndex, updateSelectedDayUrl } =
    useContext(EPGContext);

  const isDaysDisabled = isFetchingEPGGrid || isFetchingTimeslices;

  const handleOnClick = useCallback(
    (rubric: ProgramGuideRubric) => {
      updateActiveDayIndex(rubric.day);
      updateSelectedDayUrl(rubric.URLPage);
    },
    [updateActiveDayIndex, updateSelectedDayUrl]
  );

  return (
    <ContentRowGuide className={styles.daySelector} type="tvGuideDaySelector" isHorizontalList>
      {rubrics.map((rubric) => {
        return (
          <li
            className={styles.daySelector__item}
            data-ratio={`${DEFAULT_RATIO}normal`}
            key={`day-${rubric.day}-${activeDayIndex}`}
          >
            <button
              type="button"
              className={classNames(styles.daySelector__button, {
                [styles['daySelector__button--active']]: rubric.day === activeDayIndex,
              })}
              onClick={() => handleOnClick(rubric)}
              disabled={isDaysDisabled && rubric.day !== activeDayIndex}
            >
              <span className={styles.daySelector__button__day}>{rubric.displayName}</span>
              <span className={styles.daySelector__button__date}>{rubric.subtitle}</span>
            </button>
          </li>
        );
      })}
    </ContentRowGuide>
  );
}

export default DaySelector;
